<template>
  <div
    v-if="$_.get(task, 'status', '').startsWith('1-')"
    class="task-post-controls"
  >
    <post-field
      ref="postField"
      :message="form.message"
      :disabled="processing"
      :placeholder="
        `Enter your ${
          isLog
            ? 'log entry'
            : isInternal
            ? 'note'
            : isPrivate
            ? 'private message'
            : 'message'
        } here...`
      "
      :min-height="minHeight"
      :max-height="maxHeight"
      :class="{ 'is-note': isInternal }"
      @message="form.message = $event"
      @attachments="form.attachments = $event"
      @onEnter="doSubmit"
      @onEscape="$emit('onCancel')"
    />

    <button v-if="isEditing" class="button" @click="$emit('onCancel')">
      Cancel
    </button>

    <post-attach-files-button
      v-if="allowFiles"
      :disabled="processing"
      @click="$refs.postField.attachFile()"
    />

    <p v-if="visibilityMessage" class="post-visibility-msg is-size-7">
      <b-icon icon="info-circle" size="is-small" />
      {{ visibilityMessage }}
    </p>

    <post-submit-button
      :disabled="!isValidForm"
      :loading="processing"
      :label="isEditing ? 'Save' : null"
      @click="doSubmit"
    />
  </div>
</template>

<script>
import { doc, serverTimestamp } from "@firebase/firestore";
export default {
  name: "TaskPostControls",
  components: {
    "post-field": () => import("@shared/tasks/posts/_postField"),
    "post-submit-button": () => import("@shared/tasks/posts/_postSubmitButton"),
    "post-attach-files-button": () =>
      import("@shared/tasks/posts/_postAttachFilesButton")
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null
    },
    messageId: {
      type: String,
      required: false,
      default: null
    },
    taskId: {
      type: String,
      required: true
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    },
    isInternal: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    isLog: {
      type: Boolean,
      default: false
    },
    allowFiles: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: 80
    },
    maxHeight: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      processing: false,
      form: {
        message: this.message,
        attachments: []
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    hasResellerOrigins() {
      return !!this.task.resellerId;
    },
    userIsReseller() {
      return this.$store.getters["user/isReseller"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    sanitisedMessage() {
      return this.$_.trim(this.form.message);
    },
    hasValidMessage() {
      return (
        !this.$_.isEmpty(this.sanitisedMessage) &&
        !this.$_.isEqual(this.sanitisedMessage, this.message)
      );
    },
    hasInvalidAttachments() {
      return this.$_.find(this.form.attachments, ["valid.valid", false]);
    },
    isValidForm() {
      if (this.$_.isEmpty(this.form.attachments)) return this.hasValidMessage;
      return !this.hasInvalidAttachments;
    },
    isEditing() {
      return this.message && this.messageId;
    },
    visibilityMessage() {
      if (this.isInternal && this.userIsAgent)
        return "This note will only be visible to agents.";
      if (this.isPrivate && this.userIsAgent)
        return "This message will be hidden from a reseller's clients.";
      if (this.isPrivate && this.userIsReseller)
        return "This message will be hidden from your clients.";
      return null;
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.postField.focus();
      });
    }
  },
  methods: {
    checkForKeywords() {
      const keywordsRegex = /(fixed)/gim;
      const hasMatches = keywordsRegex.exec(this.sanitisedMessage);
      if (
        !this.isInternal &&
        this.userIsAgent &&
        this.hasResellerOrigins &&
        hasMatches
      ) {
        return confirm(
          "Your message contains references to 'fixed'. As this task is associated with a resellers account, is 'fixed' used in a non-brand context? If so, hit 'OK' to continue."
        );
      } else {
        return true;
      }
    },
    async doSubmit() {
      if (!this.processing && this.isValidForm) {
        this.processing = true;
        try {
          if (this.checkForKeywords()) {
            if (this.isEditing) await this.editMessage();
            else if (this.isLog) await this.addLog();
            else await this.addMessage();
            if (!this.isEditing) {
              this.$refs.postField.reset();
              this.$refs.postField.focus();
            }
          } else {
            this.$refs.postField.focus();
          }
        } catch (error) {
          console.error(error.message);
          this.$toast.open({
            message:
              error.code === "storage/canceled"
                ? `File upload cancelled`
                : `Your ${this.isLog ? "log" : "message"} could not be saved`,
            type: error.code === "storage/canceled" ? "is-warning" : "is-danger"
          });
        }
        this.processing = false;
      }
    },

    async addMessage() {
      if (this.hasValidMessage) {
        await this.$store.dispatch("tasks/addPost", {
          taskId: this.taskId,
          payload: {
            body: this.sanitisedMessage,
            isHidden: false,
            isPrivate: this.isPrivate,
            isInternal: this.isInternal,
            authorId: this.userId,
            dateCreated: serverTimestamp()
          }
        });
      }
      if (this.form.attachments.length) {
        const files = this.$_.map(this.form.attachments, a => a.file);
        this.$_.each(
          await this.$store.dispatch("tasks/uploadFiles", {
            taskId: this.taskId,
            userId: this.userId,
            isPrivate: this.isPrivate,
            isInternal: this.isInternal,
            files
          }),
          snapshot => {
            this.$emit("onUpload", snapshot);
          }
        );
      }
      return;
    },
    editMessage() {
      if (!this.hasValidMessage) return;
      return this.$store
        .dispatch("tasks/updatePost", {
          taskId: this.taskId,
          postId: this.messageId,
          payload: {
            body: this.sanitisedMessage
          }
        })
        .then(snapshot => {
          this.$emit("onEdit", snapshot);
        });
    },
    addLog() {
      if (!this.hasValidMessage) return;
      return this.$store.dispatch("log/add", {
        metadata: {
          taskPath: doc(this.$firestore, "tasks", this.taskId).path,
          message: this.sanitisedMessage
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.task-post-controls {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: start;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  .post-field {
    grid-column: 1 / span 3;
    width: 100%;
  }
  .message-submit-button {
    grid-column: 3;
  }
  .post-visibility-msg {
    align-self: center;
  }
}
</style>
