var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$_.get(_vm.task, 'status', '').startsWith('1-'))?_c('div',{staticClass:"task-post-controls"},[_c('post-field',{ref:"postField",class:{ 'is-note': _vm.isInternal },attrs:{"message":_vm.form.message,"disabled":_vm.processing,"placeholder":`Enter your ${
        _vm.isLog
          ? 'log entry'
          : _vm.isInternal
          ? 'note'
          : _vm.isPrivate
          ? 'private message'
          : 'message'
      } here...`,"min-height":_vm.minHeight,"max-height":_vm.maxHeight},on:{"message":function($event){_vm.form.message = $event},"attachments":function($event){_vm.form.attachments = $event},"onEnter":_vm.doSubmit,"onEscape":function($event){return _vm.$emit('onCancel')}}}),(_vm.isEditing)?_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$emit('onCancel')}}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.allowFiles)?_c('post-attach-files-button',{attrs:{"disabled":_vm.processing},on:{"click":function($event){return _vm.$refs.postField.attachFile()}}}):_vm._e(),(_vm.visibilityMessage)?_c('p',{staticClass:"post-visibility-msg is-size-7"},[_c('b-icon',{attrs:{"icon":"info-circle","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.visibilityMessage)+" ")],1):_vm._e(),_c('post-submit-button',{attrs:{"disabled":!_vm.isValidForm,"loading":_vm.processing,"label":_vm.isEditing ? 'Save' : null},on:{"click":_vm.doSubmit}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }